import { Configuration, TrackPurchaseRequest, TrackUserRequest, UserControllerApi } from 'clients/crm-service'
import { getBaseConfig } from './api/rest/config'
import {
  BrazeCustomEventName,
  BrazePurchaseEventDetails,
  BrazeRegisterUserAdditionalFields,
} from './braze/brazeConstants'

const getConfig = () => new Configuration({ baseOptions: getBaseConfig() })

const getUserControllerApi = (): UserControllerApi =>
  new UserControllerApi(getConfig(), process.env.NEXT_PUBLIC_CRM_SERVICE_BASE)

export const trackCrmUser = async (
  email: string,
  customEventName: BrazeCustomEventName,
  marketingConsent: boolean,
  additionalFields?: BrazeRegisterUserAdditionalFields,
) => {
  const userApi = getUserControllerApi()

  let trackUserRequest: TrackUserRequest
  if (!!additionalFields) {
    trackUserRequest = { email, customEventName, marketingConsent, additionalFields }
  } else {
    trackUserRequest = { email, customEventName, marketingConsent }
  }
  await userApi.trackUser(trackUserRequest)
}

export const trackCrmPurchase = async (purchaseDetails: BrazePurchaseEventDetails) => {
  const userApi = getUserControllerApi()

  const trackPurchaseRequest: TrackPurchaseRequest = {
    email: purchaseDetails.email,
    quizUserId: purchaseDetails.quizUserId,
    firstName: purchaseDetails.firstName,
    lastName: purchaseDetails.lastName,
    orderNumber: purchaseDetails.orderNumber,
    orderCountry: purchaseDetails.orderCountry,
    plansSlug: purchaseDetails.plansUrl,
    preorder: purchaseDetails.preorder,
    freeTrial: purchaseDetails.freeTrial,
    productId: purchaseDetails.productId,
    currency: purchaseDetails.currencyCode,
    price: purchaseDetails.price,
    quantity: purchaseDetails.quantity,
    time: new Date().toISOString(),
  }

  await userApi.trackPurchase(trackPurchaseRequest)
}
