import { FunctionComponent } from 'react'

type DiscountCode = string

type KitOptionUS = 'TEST_KIT_6_INSTALMENTS' | 'TEST_KIT_1_INSTALMENT'
type PricingExperimentAug24KitOptionUS =
  | 'US_TEST_KIT_AUG24_TIER1_1_INSTALMENT'
  | 'US_TEST_KIT_AUG24_TIER2_1_INSTALMENT'
  | 'US_TEST_KIT_AUG24_TIER3_1_INSTALMENT'
type PricingExperimentSep24KitOptionUS =
  | 'US_TEST_KIT_SEP24_TIER1_1_INSTALMENT'
  | 'US_TEST_KIT_SEP24_TIER2_1_INSTALMENT'
  | 'US_TEST_KIT_SEP24_TIER3_1_INSTALMENT'
type MembershipOptionUS = 'PROGRAM_MONTHLY' | 'PROGRAM_4_MONTH' | 'PROGRAM_YEARLY' | 'PROGRAM_4_MONTH_TRIAL'
type PricingExperimentAug24MembershipOptionUS =
  | 'US_PROGRAM_4_MONTH_AUG24_TIER1_MONTHLY_ROLLING'
  | 'US_PROGRAM_12_MONTH_AUG24_TIER2_MONTHLY_ROLLING'
  | 'US_PROGRAM_12_MONTH_AUG24_TIER3_MONTHLY_ROLLING'
type PricingExperimentSep24MembershipOptionUS =
  | 'US_PROGRAM_4_MONTH_SEP24_TIER1_MONTHLY_ROLLING'
  | 'US_PROGRAM_12_MONTH_SEP24_TIER2_MONTHLY_ROLLING'
  | 'US_PROGRAM_12_MONTH_SEP24_TIER3_MONTHLY_ROLLING'

type KitOptionGB = 'GB_TEST_KIT_PREORDER_1_INSTALMENT_A' | 'GB_TEST_KIT_PREORDER_6_INSTALMENTS_A'
type PlaceholderKitOptionGB = 'GB_TEST_KIT_6_INSTALMENTS' | 'GB_TEST_KIT_1_INSTALMENT'
type Oct23ExpBranchBKitOptionGB =
  | 'GB_FREE_TRIAL_TEST_KIT_DEC23_TIER1_1_INSTALMENT'
  | 'GB_TEST_KIT_DEC23_TIER2_1_INSTALMENT'
  | 'GB_TEST_KIT_OCT23_TIER3_1_INSTALMENT'
type Jan24ExpBranchBKitOptionGB = 'GB_TEST_KIT_JAN24_TIER1_1_INSTALMENT'
type MembershipOptionGB = 'GB_PROGRAM_MONTHLY' | 'GB_PROGRAM_4_MONTH' | 'GB_PROGRAM_YEARLY'
type NewCheckoutDesignMembershipOptionGB = 'GB_PROGRAM_4_MONTH:PAID_MONTHLY' | 'GB_PROGRAM_YEARLY:PAID_MONTHLY'
type PricingExperimentOct23BranchBMembershipOptionGB =
  | 'GB_PROGRAM_4_MONTH_DEC23_TIER1_MONTHLY_ROLLING'
  | 'GB_PROGRAM_12_MONTH_DEC23_TIER2_MONTHLY_ROLLING'
  | 'GB_PROGRAM_12_MONTH_OCT23_TIER3_MONTHLY_ROLLING'
type PricingExperimentJan24BranchBMembershipOptionGB = 'GB_PROGRAM_4_MONTH_JAN24_TIER1_MONTHLY_ROLLING'
type Dec24MembershipOptionGB =
  | 'GB_PROGRAM_4_MONTH_START_DEC24_MONTHLY_ROLLING'
  | 'GB_PROGRAM_12_MONTH_CORE_DEC24_MONTHLY_ROLLING'
  | 'GB_PROGRAM_12_MONTH_COMPLETE_DEC24_MONTHLY_ROLLING'
type Dec24KitOptionGB = 'GB_TEST_KIT_START_DEC24' | 'GB_TEST_KIT_CORE_DEC24' | 'GB_TEST_KIT_COMPLETE_DEC24'
type Dec24MembershipOptionUS =
  | 'US_PROGRAM_4_MONTH_START_DEC24_MONTHLY_ROLLING'
  | 'US_PROGRAM_12_MONTH_CORE_DEC24_MONTHLY_ROLLING'
  | 'US_PROGRAM_12_MONTH_COMPLETE_DEC24_MONTHLY_ROLLING'
type Dec24KitOptionUS = 'US_TEST_KIT_START_DEC24' | 'US_TEST_KIT_CORE_DEC24' | 'US_TEST_KIT_COMPLETE_DEC24'

type MembershipOption =
  | MembershipOptionUS
  | PricingExperimentAug24MembershipOptionUS
  | PricingExperimentSep24MembershipOptionUS
  | MembershipOptionGB
  | NewCheckoutDesignMembershipOptionGB
  | PricingExperimentOct23BranchBMembershipOptionGB
  | PricingExperimentJan24BranchBMembershipOptionGB
  | Dec24MembershipOptionGB
  | Dec24MembershipOptionUS
export type KitOption =
  | KitOptionUS
  | PricingExperimentAug24KitOptionUS
  | PricingExperimentSep24KitOptionUS
  | KitOptionGB
  | PlaceholderKitOptionGB
  | Oct23ExpBranchBKitOptionGB
  | Jan24ExpBranchBKitOptionGB
  | Dec24KitOptionGB
  | Dec24KitOptionUS
type GutHealthRetest = 'GB_GUT_HEALTH_RETEST'
type Daily30Products = 'GB_DAILY30_1_MONTH' | 'GB_DAILY30_4_MONTH' | 'US_DAILY30_1_MONTH' | 'US_DAILY30_4_MONTH'
export type ProductName = MembershipOption | KitOption | GutHealthRetest | Daily30Products
type UnitTestProductName = 'TEST1' | 'TEST2' | 'TEST3'
type DisplayProductName = 'GUT_HEALTH_RETEST_DISPLAY_PRODUCT'

type TimeInterval = 'year' | 'month' | 'day'
type ProductCategory = 'Membership' | 'Kit' | 'TrialMembership' | 'DisplayProduct' | 'GutHealthRetest' | 'Daily30'
type DiscountDuration = 'forever' | 'once'
export type DiscountTypeV2 = 'amount' | 'percent'

export const BasketTypeBuyNow = 'product'
export const BasketTypePreorder = 'preorder'
export const BasketTypeExistingCustomer = 'existingCustomer'
export type BasketType = typeof BasketTypeBuyNow | typeof BasketTypePreorder | typeof BasketTypeExistingCustomer

export const CHECKOUT_US = 'US'
export const CHECKOUT_GB = 'GB'
export type CheckoutCountry = typeof CHECKOUT_US | typeof CHECKOUT_GB

export const CURRENCY_US = 'usd'
export const CURRENCY_GB = 'gbp'
export type CurrencyCode = typeof CURRENCY_US | typeof CURRENCY_GB

export type TermsProps = Product & { showTitle: boolean; title?: string; payNowAmount?: number }

export type Product = Price & AutoGenerateProductDetail & CustomProductDetail

type ZoeProductId = ProductName | UnitTestProductName | DisplayProductName

export type StripePaymentIntentMode = 'payment_intent' | 'setup_intent'
export type StripePaymentMethod = 'card' | 'klarna'

export type AutoGenerateProductDetail = {
  productImage: string
  productCategory: ProductCategory
  itemType: string
  displayName: string
}

export type CustomProductDetail = Partial<AutoGenerateProductDetail> & {
  displayPrice?: string
  displayPriceAfterDiscount?: string
  productInfo?: string
  productGuarantee?: string
  priceAfterDiscount?: number
  payNow?: boolean
  displayProducts?: Product[]
  terms?: FunctionComponent<TermsProps>
  installmentTotalPrice?: number
  totalInstallmentPriceAfterDiscount?: number
  additionalSubscriptionDurationInfo?: string
  additionalPriceInfo?: string
  monthlyPrice?: string
  displayNameOverride?: string
  tag?: string // Needed for pricing exp Oct 23. Delete once experiment is completed.
  displayProductsMembership?: Product[] // Needed for pricing exp Oct 23. Delete once experiment is completed.
  quantity?: number
  membershipCommitmentMonths?: number
}

export type Basket = {
  items: Product[]
  country: CheckoutCountry
  currency?: CurrencyCode
  subtotal: number
  discounted_subtotal?: number
  tax_amount?: number
  tax_rate?: TaxRate
  pay_now_amount?: number
  recurring_amount?: number
  recurring_period?: TimeInterval
  discount?: BasketDiscountV2
  basket_type: BasketType
}

export type TaxRate = {
  tax_rate_id?: string
  inclusive: boolean
  percentage: number
  tax_amount: number
  transaction_code?: string
}

export type BasketDiscountV2 = {
  amount?: number
  duration?: DiscountDuration
  type?: DiscountTypeV2
  submitted_promotion_code?: DiscountCode
}

type ProductDuration = {
  interval: TimeInterval
  intervalCount: number
  instalmentsCount?: number
}

type Price = {
  currency: CurrencyCode
  price: number
  zoeProductId: ZoeProductId
  productDuration?: ProductDuration
  country: CheckoutCountry
  payNow: boolean
  nickname: string
  stripePriceId: string
}

export type Prices = {
  [zoeProductId: string]: Price
}

export type Products = {
  [zoeProductId: string]: Product
}
